import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getAllTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/transactions/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data,
    needToCancelRequest: true,
    endPoint: '/transactions/list'
  })
}

export const getAllCurrenciesService = () => {
  return axiosInstance(METHOD_TYPES.get, '/transactions/currencies', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getTransactionReportService = () => {
  return axiosInstance(METHOD_TYPES.get, '/transactions/report', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getTransactionStatusService = data => {
  return axiosInstance(METHOD_TYPES.post, '/transactions/retry-failed', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Latest detail fetched successfully'
  })
}
