import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAllCurrenciesService,
  getAllTransactionsService,
  getTransactionReportService,
  getTransactionStatusService
} from 'network/services/transaction.service'

export const fetchAllTransactions = createAsyncThunk(
  'fetch/allTransactions',
  async (data, thunkApi) => {
    try {
      const res = await getAllTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllCurrencies = createAsyncThunk(
  'fetch/allCurrencies',
  async (data, thunkApi) => {
    try {
      const res = await getAllCurrenciesService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchTransactionReport = createAsyncThunk(
  'fetch/transactionReport',
  async (_, thunkApi) => {
    try {
      const res = await getTransactionReportService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchUpdatedTransaction = createAsyncThunk(
  'fetch/updatedTransaction',
  async (data, thunkApi) => {
    try {
      const res = await getTransactionStatusService(data)
      return { res, transactionIds: data?.transactionIds }
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
