import { createSlice } from '@reduxjs/toolkit'
import { fetchAllAdmins } from 'redux-thunk/thunk/Admin/Admin'

const initialState = {
  allAdmins: null
}

const adminSlice = createSlice({
  name: 'adminSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAdmins.fulfilled, (state, action) => {
        return {
          ...state,
          allAdmins: action.payload
        }
      })
  }
})

export default adminSlice.reducer
