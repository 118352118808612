import { createAsyncThunk } from '@reduxjs/toolkit'
import { getGamesListService, getGamesSettingsService, updateGamesSettingsService } from 'network/services/gameSettings.service'
import { startLoader, stopLoader } from 'redux-thunk/redux/Loader/loaderSlice'

export const getGamesList = createAsyncThunk(
  'get/GamesList',
  async (data, thunkApi) => {
    try {
      const res = await getGamesListService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getGamesSettings = createAsyncThunk(
  'get/GamesSettings',
  async (data, thunkApi) => {
    try {
      const res = await getGamesSettingsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const updateGamesSettings = createAsyncThunk(
  'update/GamesSettings',
  async ({ data, onSuccessRedirect }, thunkApi) => {
    try {
      thunkApi.dispatch(startLoader('submitButtonLoader'))
      const res = await updateGamesSettingsService(data)
      thunkApi.dispatch(stopLoader('submitButtonLoader'))
      onSuccessRedirect()
      return res
    } catch (error) {
      thunkApi.dispatch(stopLoader('submitButtonLoader'))
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
