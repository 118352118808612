import { createAsyncThunk } from '@reduxjs/toolkit'
import { getDashboardReportService } from 'network/services/dashboard.service'

export const fetchDashboardReport = createAsyncThunk(
  'fetch/DashboardReport',
  async ({ params, downloadReport, setIsDownloading }, thunkApi) => {
    try {
      const res = await getDashboardReportService(params)
      params?.rakeReport && downloadReport(res?.rakeReport)
      return { rakeReport: params?.rakeReport, transactions: res?.transactions }
    } catch (error) {
      setIsDownloading(false)
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
