import { createSlice } from '@reduxjs/toolkit'
import {
  fetchAllCurrencies,
  fetchAllTransactions,
  fetchTransactionReport,
  fetchUpdatedTransaction
} from 'redux-thunk/thunk/Transactions/transactions'

const initialState = {
  allTransactions: null,
  allCurrencies: null,
  report: null
}

const transactionSlice = createSlice({
  name: 'transactionSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          allTransactions: action.payload
        }
      })
      .addCase(fetchAllCurrencies.fulfilled, (state, action) => {
        return {
          ...state,
          allCurrencies: action.payload
        }
      })
      .addCase(fetchTransactionReport.fulfilled, (state, action) => {
        return {
          ...state,
          report: action.payload
        }
      })
      .addCase(fetchUpdatedTransaction.fulfilled, (state, { payload }) => {
        return {
          ...state,
          allTransactions: { ...state.allTransactions, rows: updateFailedTransactionStatus({ transactionList: state.allTransactions.rows, payload }) }
        }
      })
  }
})

const updateFailedTransactionStatus = ({ transactionList, payload }) => transactionList.map(e =>
  ((payload?.transactionIds?.find(id => +id === +e.id))
    ? { ...e, manualRetry: !payload.res.success }
    : e
  )
)

export default transactionSlice.reducer
