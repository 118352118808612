import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getAllOperatorsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/operators/get-all', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getOperatorDetailService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/operators/get', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const createOperatorService = (userData) => {
  return axiosInstance(METHOD_TYPES.post, '/operators/create', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Operator created successfully'
  })
}

export const updateOperatorService = (userData) => {
  return axiosInstance(METHOD_TYPES.put, '/operators/update', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Operator updated successfully'
  })
}

export const updateOperatorSecretKeyService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/operators/update-secret-key', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Secret key updated successfully'
  })
}

export const deleteOperatorService = ({ operatorId }) => {
  return axiosInstance(METHOD_TYPES.delete, '/operators/delete', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    params: { id: operatorId },
    successMessage: 'Operator deleted successfully'
  })
}

export const updateOperatorStatusService = (userData) => {
  return axiosInstance(METHOD_TYPES.put, '/operators/update-status', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: userData,
    successMessage: 'Operator status updated successfully'
  })
}

export const getAllOperatorAdminsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/operators/get-all-operator-admin', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const createOperatorAdminService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/operators/create-operator-admin', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const updateOperatorAdminService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/operators/update-operator-admin', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
export const deleteOperatorAdminService = (data) => {
  return axiosInstance(METHOD_TYPES.delete, '/operators/delete-operator-admin', {}, {
    server: microServices.USER_URL,
    params: data,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
