import { v4 as uuidv4 } from 'uuid'
import { ROUTE_PATHS } from 'utils/constants/index'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
// import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import TourOutlinedIcon from '@mui/icons-material/TourOutlined'
import SportsEsportsIcon from '@mui/icons-material/SportsEsports'
import ListOutlinedIcon from '@mui/icons-material/ListOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'

export const sidebarOptions = [
  {
    key: uuidv4(),
    label: 'dashboard',
    path: ROUTE_PATHS.dashboard,
    icon: DashboardOutlinedIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'Players',
    path: ROUTE_PATHS.users,
    icon: GroupsOutlinedIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'Operators',
    path: ROUTE_PATHS.operators,
    icon: StarBorderOutlinedIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'Operator Admins',
    path: ROUTE_PATHS.operatorAdmins,
    icon: ManageAccountsOutlinedIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'Game Settings',
    path: ROUTE_PATHS.gameSettings,
    icon: SportsEsportsIcon,
    subMenus: [
      {
        key: uuidv4(),
        label: 'Games',
        path: ROUTE_PATHS.gamesList,
        icon: ListOutlinedIcon
      },
      {
        key: uuidv4(),
        label: 'Tournaments',
        path: ROUTE_PATHS.tournaments,
        icon: TourOutlinedIcon
      },
      {
        key: uuidv4(),
        label: 'Rounds',
        path: ROUTE_PATHS.tournamentRoundsList,
        icon: ListOutlinedIcon
      }
    ]
  },
  {
    key: uuidv4(),
    label: 'Transactions',
    path: ROUTE_PATHS.transactions,
    icon: PaidOutlinedIcon,
    subMenus: []
  }
  // {
  //   key: uuidv4(),
  //   label: 'Withdraw Requests',
  //   path: ROUTE_PATHS.withdrawRequests,
  //   icon: FeedOutlinedIcon,
  //   subMenus: []
  // },
  // {
  //   key: uuidv4(),
  //   label: 'Reports by Game',
  //   path: ROUTE_PATHS.gameReports,
  //   icon: InsightsOutlinedIcon,
  //   subMenus: []
  // }
]
