import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getOperatorDetailService,
  createOperatorService,
  getAllOperatorsService,
  updateOperatorService,
  updateOperatorStatusService,
  deleteOperatorService,
  getAllOperatorAdminsService,
  createOperatorAdminService,
  updateOperatorAdminService,
  deleteOperatorAdminService,
  updateOperatorSecretKeyService
} from 'network/services/operators.service'
import { startLoader, stopLoader } from 'redux-thunk/redux/Loader/loaderSlice'

export const fetchAllOperators = createAsyncThunk(
  'fetch/allOperators',
  async (data, thunkApi) => {
    try {
      const res = await getAllOperatorsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllOperatorAdmins = createAsyncThunk(
  'fetch/allOperatorAdmins',
  async (data, thunkApi) => {
    try {
      const res = await getAllOperatorAdminsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchOperatorDetails = createAsyncThunk(
  'fetch/operatorDetail',
  async ({ data }, thunkApi) => {
    try {
      thunkApi.dispatch(startLoader('submitButtonLoader'))
      const res = await getOperatorDetailService(data)
      thunkApi.dispatch(stopLoader('submitButtonLoader'))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const createOperator = createAsyncThunk(
  'create/operator',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createOperatorService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateOperator = createAsyncThunk(
  'update/operator',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateOperatorService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateOperatorSecretKey = createAsyncThunk(
  'update/operatorSecretKey',
  async ({ data }, thunkApi) => {
    try {
      const res = await updateOperatorSecretKeyService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const deleteOperator = createAsyncThunk(
  'delete/operator',
  async ({ operatorId, onSuccess }, thunkApi) => {
    try {
      const res = await deleteOperatorService({ operatorId })
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateOperatorStatus = createAsyncThunk(
  'update/operatorStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateOperatorStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const createOperatorAdmin = createAsyncThunk(
  'create/operatorAdmin',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createOperatorAdminService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateOperatorAdmin = createAsyncThunk(
  'update/operatorAdmin',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateOperatorAdminService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const deleteOperatorAdmin = createAsyncThunk(
  'update/operatorAdmin',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await deleteOperatorAdminService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
