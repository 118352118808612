import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { useSidebarController } from './controller/useSidebarController'
import CustomList from './components/CustomList/index'
import AppDrawer from 'components/ui-kit/AppDrawer/index'
import { handleDrawerToggle } from 'redux-thunk/redux/Ham/hamSlice'
import { sidebarOptions } from './sidebarOptions'

const Sidebar = () => {
  const dispatch = useDispatch()
  const { t, location, expandedMenuItem, handleDrawerOptions } = useSidebarController()
  const isSideBarOpen = useSelector(state => state.ham.openHam)

  return (
    <AppDrawer
      openDrawer={isSideBarOpen}
      handleDrawer={() => dispatch(handleDrawerToggle())}
      text={t('adminUser')}
    >
      <Box>
        <CustomList
          t={t}
          location={location}
          list={sidebarOptions}
          handleDrawerOptions={handleDrawerOptions}
          expandedMenuItem={expandedMenuItem}
        />
      </Box>
    </AppDrawer>
  )
}

export default Sidebar
