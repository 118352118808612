import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getAllTournamentsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'tournaments/get-all', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getTournamentDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'tournaments/get', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const createTournamentService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'tournaments/create', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const updateTournamentService = (data) => {
  return axiosInstance(METHOD_TYPES.put, 'tournaments/update', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const deleteTournamentService = (data) => {
  return axiosInstance(METHOD_TYPES.delete, 'tournaments/delete', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllRoundsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'tournaments/get-rounds', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
