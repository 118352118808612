import PropTypes from 'prop-types'
import { Box, Divider } from '@mui/material'
import { DrawerHeader, StyledDrawer } from './styles'
import CustomTypography from 'components/ui-kit/Typography/index'

const AppDrawer = ({ children, openDrawer, text }) => {
  return (
    <StyledDrawer
      anchor='left'
      variant='persistent'
      open={openDrawer}
    >
      <Box>
        <DrawerHeader>
          <img
            style={{ width: '180px', margin: '15px auto' }}
            src='/images/logo/logo.png' alt='logo'
          />
          <CustomTypography
            sx={{ textAlign: 'center' }}
            variant='h6'
            value={text}
          />
        </DrawerHeader>
      </Box>
      <Divider sx={{ borderColor: 'white', margin: '10px 0px' }} light />
      <Box sx={{ overflow: 'auto' }}>
        {children}
      </Box>
    </StyledDrawer>
  )
}

export default AppDrawer

AppDrawer.defaultProps = {
  openDrawer: true,
  text: 'Default'
}

AppDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired
}
