import { combineReducers } from '@reduxjs/toolkit'
import authSlice from './Auth/authSlice'
import hamSlice from './Ham/hamSlice'
import toasterSlice from './Toaster/toasterSlice'
import loaderSlice from './Loader/loaderSlice'
import playerSlice from './Players/playerSlice'
import transactionsSlice from './Transactions/transactionsSlice'
import gameReportsSlice from './GameReports/gameReportsSlice'
import adminSlice from './Admin/adminSlice'
import gameSettingsSlice from './GameSettings/gameSettingsSlice'
import dashboardSlice from './Dashboard/dashboardSlice'
import operatorSlice from './Operators/operatorSlice'
import tournamentSlice from './Tournaments/tournamentSlice'

export const rootReducer = combineReducers({
  auth: authSlice,
  ham: hamSlice,
  dashboard: dashboardSlice,
  toaster: toasterSlice,
  loader: loaderSlice,
  players: playerSlice,
  transactions: transactionsSlice,
  gameReports: gameReportsSlice,
  admins: adminSlice,
  operators: operatorSlice,
  tournaments: tournamentSlice,
  gameSettings: gameSettingsSlice
})
