import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCrashGameReportService, getDiceGameReportService, getFlipCoinGameReportService, getHiLoGameReportService, getXRouletteGameReportService } from 'network/services/game.service'

export const fetchCrashGameReport = createAsyncThunk(
  'fetch/crashGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getCrashGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchDiceGameReport = createAsyncThunk(
  'fetch/diceGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getDiceGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchFlipCoinGameReport = createAsyncThunk(
  'fetch/flipCoinGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getFlipCoinGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchHiLoGameReport = createAsyncThunk(
  'fetch/hiLoGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getHiLoGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchXRouletteGameReport = createAsyncThunk(
  'fetch/xRouletteGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getXRouletteGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
