import { createSlice } from '@reduxjs/toolkit'
import { fetchDashboardReport } from 'redux-thunk/thunk/Dashboard/dashboard'

const initialState = {
  report: null
}

const gameReportsSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardReport.fulfilled, (state, { payload }) => {
        if (!payload.rakeReport) {
          return {
            ...state,
            report: payload
          }
        }
      })
  }
})

export default gameReportsSlice.reducer
