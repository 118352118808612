import { createSlice } from '@reduxjs/toolkit'
import { getAllTournamentRounds, getAllTournaments, getRoundDetails, getTournamentDetails } from 'redux-thunk/thunk/Tournaments/tournaments'

const initialState = {
  allTournaments: null,
  allTournamentRounds: null,
  tournamentDetails: null,
  roundDetails: null,
  prevRoundLoader: false,
  nextRoundLoader: false
}

const operatorSlice = createSlice({
  name: 'tournamentSlice',
  initialState,
  reducers: {
    startPrevRoundLoader: (state, action) => {
      return {
        ...state,
        prevRoundLoader: true
      }
    },
    startNextRoundLoader: (state, action) => {
      return {
        ...state,
        nextRoundLoader: true
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTournaments.fulfilled, (state, action) => {
        return {
          ...state,
          allTournaments: action.payload
        }
      })
      .addCase(getAllTournamentRounds.fulfilled, (state, action) => {
        return {
          ...state,
          allTournamentRounds: action.payload
        }
      })
      .addCase(getTournamentDetails.fulfilled, (state, action) => {
        return {
          ...state,
          tournamentDetails: action.payload
        }
      })
      .addCase(getRoundDetails.fulfilled, (state, action) => {
        return {
          ...state,
          roundDetails: action.payload,
          prevRoundLoader: false,
          nextRoundLoader: false
        }
      })
      .addCase(getRoundDetails.rejected, (state, action) => {
        return {
          ...state,
          prevRoundLoader: false,
          nextRoundLoader: false
        }
      })
  }
})

export const { startPrevRoundLoader, startNextRoundLoader } = operatorSlice.actions

export default operatorSlice.reducer
