import { createSlice } from '@reduxjs/toolkit'
import { fetchCrashGameReport, fetchDiceGameReport, fetchFlipCoinGameReport } from 'redux-thunk/thunk/GameReports/gameReports'

const initialState = {
  crashGameReport: null,
  diceGameReport: null,
  flipCoinGameReport: null
}

const gameReportsSlice = createSlice({
  name: 'gameReportsSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchCrashGameReport.fulfilled, (state, action) => {
        return {
          ...state,
          crashGameReport: action.payload
        }
      })
      .addCase(fetchDiceGameReport.fulfilled, (state, action) => {
        return {
          ...state,
          diceGameReport: action.payload
        }
      })
      .addCase(fetchFlipCoinGameReport.fulfilled, (state, action) => {
        return {
          ...state,
          flipCoinGameReport: action.payload
        }
      })
  }
})

export default gameReportsSlice.reducer
