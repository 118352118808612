import { EnglishIcon } from 'components/ui-kit/icons/iconComponents/EnglishIcon'
import { FrenchIcon } from 'components/ui-kit/icons/iconComponents/FrenchIcon'
import { GermanIcon } from 'components/ui-kit/icons/iconComponents/GermanIcon'
import { PortugalIcon } from 'components/ui-kit/icons/iconComponents/PortugalIcon'
import { RussianIcon } from 'components/ui-kit/icons/iconComponents/RussianIcon'
import { SpanishIcon } from 'components/ui-kit/icons/iconComponents/SpanishIcon'

/* ==========================================================================
  LANGUAGES
  ========================================================================== */
const LANGUAGES = [
  { label: 'English', languageCode: 'en', flag: EnglishIcon },
  { label: 'German', languageCode: 'de', flag: GermanIcon },
  { label: 'Spanish', languageCode: 'es', flag: SpanishIcon },
  { label: 'French', languageCode: 'fr', flag: FrenchIcon },
  { label: 'Portuguese', languageCode: 'pt', flag: PortugalIcon },
  { label: 'Russian', languageCode: 'ru', flag: RussianIcon }
]

/* ==========================================================================
  HTTP Method Types
========================================================================== */
const METHOD_TYPES = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch'
}

/* ==========================================================================
LocalStorage / Cookie data
========================================================================== */
const TOKEN = 'admin_authtoken'
const LANGUAGE = 'admin_language'
const ADMIN_ID = 'admin_id'
const ROLE = 'operator_role'

/* ==========================================================================
Operator Roles
========================================================================== */
const OPERATOR_ROLES = {
  operator: 'OPERATOR',
  operatorUser: 'OPERATOR_USER'
}

/* ==========================================================================
Loader types
========================================================================== */
const LOADER_TYPE = {
  SCALE: 'scale',
  PULSE: 'pulse'
}

/* ==========================================================================
  TOASTER / NOTIFICATION Types
========================================================================== */
const TOASTER_TYPE = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
}

/* ==========================================================================
  Loader Types
========================================================================== */
const LOADER_HANDLER_TYPES = {
  page: 'pageLoader',
  submit: 'submitButtonLoader',
  table: 'tableLoader'
}

/* ==========================================================================
  Table Cell Type
========================================================================== */
const TABLE_CELL_TYPE = {
  status: 'status',
  switch: 'switch',
  button: 'button',
  date: 'date',
  text: 'text',
  number: 'number',
  default: 'default',
  updateIconButton: 'updateIconButton',
  deleteIconButton: 'deleteIconButton',
  actionCell: 'actionCell',
  enableDisable: 'enableDisable',
  viewDetail: 'viewDetail',
  viewEditDelete: 'viewEditDelete',
  settings: 'settings'
}

/* ==========================================================================
  All the navigation route Paths
========================================================================== */
// const gamesSectionParentRoute = '/games'
// const playersSectionParentRoute = '/players'
// const performanceSectionParentRoute = '/performance'

const ROUTE_PATHS = {
  rootPath: '/',
  login: '/login',
  changePassword: '/change-password',
  resetPassword: '/reset-password/:adminId/:token',
  settings: '/settings',
  gameSettings: '/:gameName/game-settings',
  dashboard: '/dashboard',
  profile: '/profile',
  editGameSettings: '/game-settings/edit',
  users: '/users',
  usersDetail: '/users/:userId/details',
  createUser: '/users/create',
  editUser: '/users/:userId/edit',
  operators: '/operators',
  operatorsDetail: '/operators/:operatorId/users',
  createOperator: '/operators/create',
  viewOperator: '/operators/details',
  editOperator: '/operators/:operatorId/edit',
  operatorAdmins: '/operator-admins',
  createOperatorAdmin: '/operator-admin/create',
  viewOperatorAdmin: '/operator-admin/details',
  editOperatorAdmin: '/operator-admin/edit',
  tournaments: '/tournaments',
  createTournament: '/tournament/create',
  viewTournament: '/tournament/details',
  editTournament: '/tournament/edit',
  gamesList: '/games-list',
  tournamentRoundsList: '/rounds-list',
  viewRoundDetail: '/round-detail',

  admins: '/admins',
  transactions: '/transactions',
  withdrawRequests: '/withdraw-requests',
  gameReports: '/game-reports/',
  notFound: '/not-found'
}

/* ==========================================================================
  DIALOG Types
========================================================================== */
const DIALOG_TYPE = {
  add: 'add',
  update: 'update',
  delete: 'delete',
  view: 'view',
  hide: ''
}

/* ==========================================================================
  Miscellaneous Constants
========================================================================== */
export const DEFAULT_OFFSET_NO = 10
export const DEFAULT_PAGE_NO = 1

// Export All constant variables
export {
  TOASTER_TYPE,
  LANGUAGES,
  TOKEN,
  LANGUAGE,
  ADMIN_ID,
  ROLE,
  OPERATOR_ROLES,
  ROUTE_PATHS,
  LOADER_TYPE,
  METHOD_TYPES,
  LOADER_HANDLER_TYPES,
  TABLE_CELL_TYPE,
  DIALOG_TYPE
}

export const CARD_DECK = {
  1: { suit: 'Club', suitRank: 1, description: 'Two', shortString: '2C', sort: 2, value: 2 },
  2: { suit: 'Club', suitRank: 1, description: 'Three', shortString: '3C', sort: 3, value: 3 },
  3: { suit: 'Club', suitRank: 1, description: 'Four', shortString: '4C', sort: 4, value: 4 },
  4: { suit: 'Club', suitRank: 1, description: 'Five', shortString: '5C', sort: 5, value: 5 },
  5: { suit: 'Club', suitRank: 1, description: 'Six', shortString: '6C', sort: 6, value: 6 },
  6: { suit: 'Club', suitRank: 1, description: 'Seven', shortString: '7C', sort: 7, value: 7 },
  7: { suit: 'Club', suitRank: 1, description: 'Eight', shortString: '8C', sort: 8, value: 8 },
  8: { suit: 'Club', suitRank: 1, description: 'Nine', shortString: '9C', sort: 9, value: 9 },
  9: { suit: 'Club', suitRank: 1, description: 'Ten', shortString: '10C', sort: 10, value: 0 },
  10: { suit: 'Club', suitRank: 1, description: 'Jack', shortString: 'JC', sort: 11, value: 0 },
  11: { suit: 'Club', suitRank: 1, description: 'Queen', shortString: 'QC', sort: 12, value: 0 },
  12: { suit: 'Club', suitRank: 1, description: 'King', shortString: 'KC', sort: 13, value: 0 },
  13: { suit: 'Club', suitRank: 1, description: 'Ace', shortString: 'AC', sort: 14, value: 1 },
  14: { suit: 'Diamond', suitRank: 2, description: 'Two', shortString: '2D', sort: 2, value: 2 },
  15: { suit: 'Diamond', suitRank: 2, description: 'Three', shortString: '3D', sort: 3, value: 3 },
  16: { suit: 'Diamond', suitRank: 2, description: 'Four', shortString: '4D', sort: 4, value: 4 },
  17: { suit: 'Diamond', suitRank: 2, description: 'Five', shortString: '5D', sort: 5, value: 5 },
  18: { suit: 'Diamond', suitRank: 2, description: 'Six', shortString: '6D', sort: 6, value: 6 },
  19: { suit: 'Diamond', suitRank: 2, description: 'Seven', shortString: '7D', sort: 7, value: 7 },
  20: { suit: 'Diamond', suitRank: 2, description: 'Eight', shortString: '8D', sort: 8, value: 8 },
  21: { suit: 'Diamond', suitRank: 2, description: 'Nine', shortString: '9D', sort: 9, value: 9 },
  22: { suit: 'Diamond', suitRank: 2, description: 'Ten', shortString: '10D', sort: 10, value: 0 },
  23: { suit: 'Diamond', suitRank: 2, description: 'Jack', shortString: 'JD', sort: 11, value: 0 },
  24: { suit: 'Diamond', suitRank: 2, description: 'Queen', shortString: 'QD', sort: 12, value: 0 },
  25: { suit: 'Diamond', suitRank: 2, description: 'King', shortString: 'KD', sort: 13, value: 0 },
  26: { suit: 'Diamond', suitRank: 2, description: 'Ace', shortString: 'AD', sort: 14, value: 1 },
  27: { suit: 'Heart', suitRank: 3, description: 'Two', shortString: '2H', sort: 2, value: 2 },
  28: { suit: 'Heart', suitRank: 3, description: 'Three', shortString: '3H', sort: 3, value: 3 },
  29: { suit: 'Heart', suitRank: 3, description: 'Four', shortString: '4H', sort: 4, value: 4 },
  30: { suit: 'Heart', suitRank: 3, description: 'Five', shortString: '5H', sort: 5, value: 5 },
  31: { suit: 'Heart', suitRank: 3, description: 'Six', shortString: '6H', sort: 6, value: 6 },
  32: { suit: 'Heart', suitRank: 3, description: 'Seven', shortString: '7H', sort: 7, value: 7 },
  33: { suit: 'Heart', suitRank: 3, description: 'Eight', shortString: '8H', sort: 8, value: 8 },
  34: { suit: 'Heart', suitRank: 3, description: 'Nine', shortString: '9H', sort: 9, value: 9 },
  35: { suit: 'Heart', suitRank: 3, description: 'Ten', shortString: '10H', sort: 10, value: 0 },
  36: { suit: 'Heart', suitRank: 3, description: 'Jack', shortString: 'JH', sort: 11, value: 0 },
  37: { suit: 'Heart', suitRank: 3, description: 'Queen', shortString: 'QH', sort: 12, value: 0 },
  38: { suit: 'Heart', suitRank: 3, description: 'King', shortString: 'KH', sort: 13, value: 0 },
  39: { suit: 'Heart', suitRank: 3, description: 'Ace', shortString: 'AH', sort: 14, value: 1 },
  40: { suit: 'Spade', suitRank: 4, description: 'Two', shortString: '2S', sort: 2, value: 2 },
  41: { suit: 'Spade', suitRank: 4, description: 'Three', shortString: '3S', sort: 3, value: 3 },
  42: { suit: 'Spade', suitRank: 4, description: 'Four', shortString: '4S', sort: 4, value: 4 },
  43: { suit: 'Spade', suitRank: 4, description: 'Five', shortString: '5S', sort: 5, value: 5 },
  44: { suit: 'Spade', suitRank: 4, description: 'Six', shortString: '6S', sort: 6, value: 6 },
  45: { suit: 'Spade', suitRank: 4, description: 'Seven', shortString: '7S', sort: 7, value: 7 },
  46: { suit: 'Spade', suitRank: 4, description: 'Eight', shortString: '8S', sort: 8, value: 8 },
  47: { suit: 'Spade', suitRank: 4, description: 'Nine', shortString: '9S', sort: 9, value: 9 },
  48: { suit: 'Spade', suitRank: 4, description: 'Ten', shortString: '10S', sort: 10, value: 0 },
  49: { suit: 'Spade', suitRank: 4, description: 'Jack', shortString: 'JS', sort: 11, value: 0 },
  50: { suit: 'Spade', suitRank: 4, description: 'Queen', shortString: 'QS', sort: 12, value: 0 },
  51: { suit: 'Spade', suitRank: 4, description: 'King', shortString: 'KS', sort: 13, value: 0 },
  52: { suit: 'Spade', suitRank: 4, description: 'Ace', shortString: 'AS', sort: 14, value: 1 }
}

export const TRANSACTION_TYPE = [
  { id: 1, label: 'All', value: '' },
  { id: 2, label: 'Bet', value: 'bet' },
  { id: 3, label: 'Win', value: 'win' },
  { id: 4, label: 'Debit', value: 'debit' },
  { id: 5, label: 'Credit', value: 'credit' }
]

export const TRANSACTION_STATUS = [
  { id: 1, label: 'All', value: '' },
  { id: 2, label: 'Success', value: 'success' },
  { id: 3, label: 'Failed', value: 'failed' }
]
