import { lazy } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ROUTE_PATHS } from 'utils/constants/index'
import Login from 'pages/Auth/Login'

export const routerList = [
  {
    key: uuidv4(),
    path: ROUTE_PATHS.dashboard,
    component: lazy(() => import('pages/Dashboard')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.users,
    component: lazy(() => import('pages/Players/PlayerManagement')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operators,
    component: lazy(() => import('pages/Operator/OperatorManagement')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operatorAdmins,
    component: lazy(() => import('pages/OperatorAdmin/OperatorAdminsManagement')),
    hasAuth: true
  },
  {

    key: uuidv4(),
    path: ROUTE_PATHS.gamesList,
    component: lazy(() => import('pages/GamesList')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.gameSettings,
    component: lazy(() => import('pages/GameSettings')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.tournaments,
    component: lazy(() => import('pages/Tournament/TournamentManagement')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.tournamentRoundsList,
    component: lazy(() => import('pages/Round/TournamentRoundsList')),
    hasAuth: true

  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.transactions,
    component: lazy(() => import('pages/TransactionsList')),
    hasAuth: true
  },
  // {
  //   key: uuidv4(),
  //   path: ROUTE_PATHS.withdrawRequests,
  //   component: lazy(() => import('pages/WithdrawRequests')),
  //   hasAuth: true,
  // },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.gameReports,
    component: lazy(() => import('pages/GameReports')),
    hasAuth: true
  },
  {

    key: uuidv4(),
    path: ROUTE_PATHS.login,
    component: Login,
    hasAuth: false
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.resetPassword,
    component: lazy(() => import('pages/Auth/ResetPassword')),
    hasAuth: false
  },
  {
    key: uuidv4(),
    path: `${ROUTE_PATHS.settings}/*`,
    component: lazy(() => import('pages/Settings')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.profile,
    component: lazy(() => import('pages/Profile')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: `${ROUTE_PATHS.settings}/*`,
    component: lazy(() => import('pages/Settings')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.changePassword,
    component: lazy(() => import('pages/Auth/ChangePassword')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.createUser,
    component: lazy(() => import('pages/Players/CreatePlayer')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.createTournament,
    component: lazy(() => import('pages/Tournament/CreateTournament')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.viewTournament,
    component: lazy(() => import('pages/Tournament/ViewTournament')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.editTournament,
    component: lazy(() => import('pages/Tournament/EditTournament')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.editUser,
    component: lazy(() => import('pages/Players/EditPlayer')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.createOperator,
    component: lazy(() => import('pages/Operator/CreateOperator')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.viewOperator,
    component: lazy(() => import('pages/Operator/ViewOperator')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.editOperator,
    component: lazy(() => import('pages/Operator/EditOperator')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.createOperatorAdmin,
    component: lazy(() => import('pages/OperatorAdmin/CreateOperatorAdmin')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.viewOperatorAdmin,
    component: lazy(() => import('pages/OperatorAdmin/ViewOperatorAdmin')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.editOperatorAdmin,
    component: lazy(() => import('pages/OperatorAdmin/EditOperatorAdmin')),
    hasAuth: true,
    hasSideBarOption: false,
    subMenus: []
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.editGameSettings,
    component: lazy(() => import('pages/EditGameSetting')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.usersDetail,
    component: lazy(() => import('pages/Players/PlayerDetails')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.viewRoundDetail,
    component: lazy(() => import('pages/Round/ViewRoundDetail')),
    hasAuth: true
  }
]
