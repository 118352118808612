import { createAsyncThunk } from '@reduxjs/toolkit'
import { createTournamentService, deleteTournamentService, getAllRoundsService, getAllTournamentsService, getTournamentDetailsService, updateTournamentService } from 'network/services/tournament.service'
import { startNextRoundLoader, startPrevRoundLoader } from 'redux-thunk/redux/Tournaments/tournamentSlice'

export const getAllTournaments = createAsyncThunk(
  'get/tournament',
  async (data, thunkApi) => {
    try {
      const res = await getAllTournamentsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const createTournament = createAsyncThunk(
  'create/tournament',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createTournamentService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateTournament = createAsyncThunk(
  'update/tournament',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateTournamentService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const deleteTournament = createAsyncThunk(
  'delete/tournament',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await deleteTournamentService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getAllTournamentRounds = createAsyncThunk(
  'get/tournament-rounds',
  async (data, thunkApi) => {
    try {
      const res = await getAllRoundsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getTournamentDetails = createAsyncThunk(
  'get/tournament-detail',
  async (data, thunkApi) => {
    try {
      const res = await getTournamentDetailsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getRoundDetails = createAsyncThunk(
  'get/tournament-rounds-detail',
  async (data, thunkApi) => {
    try {
      if (data?.prevRound) {
        thunkApi.dispatch(startPrevRoundLoader())
      }
      if (data?.nextRound) {
        thunkApi.dispatch(startNextRoundLoader())
      }
      const res = await getAllRoundsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
